<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Páginas</h4>

          <div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'pagina' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="tableData.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <b-table
              :items="tableData"
              :fields="fields"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              caption-top
              hover
              search
              head-variant="light"
            >
              <template #cell(image)="data">
                <div v-if="data.item.image != null && data.item.image != ''">
                  <span v-for="img in data.item.image" :key="img.id">
                    <img
                      v-if="img.capa == 1"
                      :src="img.image300"
                      alt=""
                      class="avatar-md h-auto d-block rounded"
                      loading="lazy"
                    />
                  </span>
                </div>
                <img
                  v-else
                  src="@/assets/images/notpro.jpg"
                  alt=""
                  class="avatar-md h-auto d-block rounded"
                  loading="lazy"
                />
              </template>
              <template #cell(action)="data">
                <a
                  v-b-tooltip.hover.top
                  title="Editar"
                  href="#"
                  @click.prevent="
                    $router.push({
                      name: 'page-edit',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <i class="fas fa-pencil-alt text-success"></i>
                </a>
                <a
                  v-b-tooltip.hover.top
                  title="Remover"
                  href="#"
                  @click.prevent="removerItem(data.index, data.item)"
                >
                  <i class="fas fa-trash-alt text-danger ml-4"></i>
                </a>
              </template>
              <template #cell(created_at)="data">
                {{ data.item.created_at | moment('DD/MM/YYYY') }}
              </template>
              <template #cell(status)="data">
                <span
                  class="badge badge-pill badge-soft-success font-size-12"
                  :class="{
                    'badge-soft-success': `${data.item.status}` == 0,
                    'badge-soft-danger': `${data.item.status}` == 1,
                  }"
                >
                  {{ data.item.status == 0 ? 'Ativa' : 'Bloqueada' }}
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  data() {
    return {
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {
          key: 'image',
          label: 'Capa',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Título',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Ação',
          sortable: false,
        },
      ],

      tableData: [],

      filtro: {
        limit: 10,
        page: 1,
      },

      loading: true,
      error: false,
    }
  },
  methods: {
    ...mapActions('page', ['ActionPageAll', 'ActionPageUpdate']),
    async infiniteHandler($state) {
      try {
        await this.ActionPageAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.tableData.push(...res.data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.tableData = []
        $state.complete()
      }
    },
    removerItem(index, data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(index, data)
        }
      })
    },
    async removerItemUp(index, data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionPageUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.tableData.splice(this.tableData.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
